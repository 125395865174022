import { IVoterHistoryRecord } from "./api";

export function makeImagePath(path: string, format?: string) {
  return `https://image.tmdb.org/t/p/${format ? format : "original"}/${path}`;
}


export const isFirstNameValid = (fn: string) => {
  if(fn && fn.length > 1 && fn.length < 50) {
    console.log('Valid First Name:', fn);
    return true;
  } else {
    return false;
  }
}

export const isLastNameValid = (ln: string) => {
  if(ln && ln.length > 1 && ln.length < 50) {
    console.log('Valid Last Name:', ln);
    return true;
  } else {
    return false;
  }
}


export const isZipCodeValid = (zip: string) => {
  if(zip && zip.length > 3 && zip.startsWith('4')) {
    console.log('Valid Zip Code:', zip);
    return true;
  } else {
    return false;
  }
}


export const isYOBValid = (yob: string) => {
  if(yob && yob.length > 2) { // parseInt(yob) < 2004 && parseInt(yob) > 1915) {
    console.log('Valid YOB:', yob);
    return true;
  } else {
    return false;
  }
}


export const isEmailValid = (em: string) => {
  if(em.includes('@') && em.includes('.') && em.length>5) {
    console.log('Valid Email:', em);
    return true;
  } else {
    return false;
  }
}

export const isValidPhone = (phn: string) => {
  if(phn.length > 6) {
    console.log('Valid Phone:', phn);
    return true;
  } else {
    return false;
  }
}

export const isCommentsValid = (cmnts: string) => {
  if(cmnts.length > 40) {
    console.log('Valid Comments:', cmnts);
    return true;
  } else {
    return false;
  }
}


export const handleVotedDescription = (voted_absentee:string, qvf_file:string): string => {
  const absenteeStatus = voted_absentee === 'Y' ? 'ABSENTEE.' : 'IN PERSON.';

  return ('The Qualified Voter File from ').concat(qvf_file,  ' indicates you voted ', absenteeStatus);
}

export const handleNotVotedDescription = (qvf_file:string): string => {
  return ('The Qualified Voter File from ').concat(qvf_file,  ' indicates you DID NOT VOTE in this election.');
}

export type ISummary = {
  ok: boolean
  color: string
  description: string
}

export const handleSummary = (record: IVoterHistoryRecord):ISummary => {
  if(record.participation === 'Y') {
    console.log('Participation:', record.election_date, record.participation);
    return ({
        ok: true,
        color: 'white',
        description: handleVotedDescription(record.voted_absentee, record.qvf_file)
    })
  } else {
      console.log('Participation:', record.election_date, record.participation);
      return ({
          ok: false,
          color: 'red',
          description: handleNotVotedDescription(record.qvf_file)
      })
  }
}

