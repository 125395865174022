import { Banner, ContentContainer, Notes, OverView, Title, Wrapper } from "../Components/Common";
import ContactDetailsForm from "../Components/ContactDetailsForm";


function Contact() {

  return (
    <Wrapper>
        <>
          <Banner>
            <Title>Audit Your Vote</Title>
            <OverView>Time to engage. Please take the time to report the error. </OverView>
            <Notes>Provide some information that will help us get you the information for an affidavit to correct the record.</Notes>
          </Banner>
          <ContentContainer>
            <ContactDetailsForm />
          </ContentContainer>
        </>
    </Wrapper>
  );
}

export default Contact;
