import { Box, Button, Flex } from 'rebass';
import { VoterHistory } from './VoterHistory';
import { useForm } from 'react-hook-form';
import { Input, Label } from '@rebass/forms';
import { ErrorLine } from './Common';
import { useState } from 'react';

interface IFormInput {
    fn: string;
    ln: string;
    zip: number;
    yob: number;
}

export default function DetailsForm() {
    const [formData, setFormData] = useState<IFormInput>();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
      } = useForm<IFormInput>({ mode: 'onChange' });
    const [showVoterHistory, setShowVoterHistory] = useState<boolean>(false);

    const onSubmit = (data: IFormInput) => {
      // TODO use history or link to store data on state and redirect to voter/:fn/:ln/:yob/:zip
      // Use something like Link in the button below to pass in params.
      if (isValid) {
        setShowVoterHistory(true);
        setFormData(data);
        console.log(JSON.stringify(formData));
      }
    }; 

    const minYear = new Date().getFullYear() - 111;
    const maxYear = new Date().getFullYear() - 17;

    return (
    <Box>
      <Box py={1} as='form' onSubmit={handleSubmit(onSubmit)}>
      <Flex mx={-2} mb={3} >
        <Box width={1} px={2}>      
          <Label pt={2} fontSize={2}>First Name</Label>
          <Input type='text'
            {...register('fn', {
              required: true,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i
            })}
          />
          {errors?.fn?.type === 'required' && (
            <ErrorLine>This field is required</ErrorLine>
          )}
          {errors?.fn?.type === 'maxLength' && (
            <ErrorLine>First name cannot exceed 20 characters</ErrorLine>
          )}
          {errors?.fn?.type === 'pattern' && (
            <ErrorLine>Alphabetical characters only</ErrorLine>
          )}
        </Box>
        <Box width={1} px={2}>
          <Label pt={2} fontSize={2}>Last Name</Label>
          <Input type='text'
            {...register('ln', { 
              required: true,
              maxLength: 50,
              pattern: /^[A-Za-z]+$/i 
            })} />
          {errors?.fn?.type === 'required' && (
            <ErrorLine>This field is required</ErrorLine>
          )}
          {errors?.ln?.type === 'pattern' && (
            <ErrorLine>Alphabetical characters only.</ErrorLine>
          )}
        </Box>
        <Box width={1} px={2}>
          <Label pt={2} fontSize={2}>Zip Code</Label>
          <Input type='number'
            {...register('zip', { 
              valueAsNumber: true,
              required: true,
              min: 10000, 
              max: 99999
              })} />
            {errors?.fn?.type === 'required' && (
                <ErrorLine>This field is required.</ErrorLine>
            )}
            {errors.zip && (
                <ErrorLine>Must be a valid zip code.</ErrorLine>
            )}
        </Box>
        <Box width={1} px={2}>
          <Label pt={2} fontSize={2}>Year of Birth</Label>
          <Input type='number' 
            {...register('yob', { 
              valueAsNumber: true,
              required: true,
              max: maxYear, 
              min: minYear
              })} />
            {errors?.yob?.type === 'required' && (
                <ErrorLine>This field is required.</ErrorLine>
            )}              
            {errors?.yob?.type === 'min' && (
                <ErrorLine>Your age is over the maximum allowed.</ErrorLine>
            )}
            {errors?.yob?.type === 'max' && (
                <ErrorLine>You are too young to vote.</ErrorLine>
            )}
        </Box>
        </Flex>
        {isValid &&
        <Box>
          <Flex>
              <Box px={2} py={2} ml='auto'>
                  <Button type='submit'
                      backgroundColor='red'
                      sx={{
                          ':hover': {
                          backgroundColor: 'tomato',
                          }}}>
                      Search
                  </Button>
              </Box>
          </Flex> 
          <Box>
          { showVoterHistory && formData &&
              <VoterHistory  
                  firstName={formData.fn}
                  lastName={formData.ln}
                  zipCode={formData.zip.toString()}
                  yearOfBirth={formData.yob.toString()}
              /> }
          </Box>
        </Box>
        }       
        </Box>
    </Box>
    )
}