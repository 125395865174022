import styled from "styled-components";
import { motion } from "framer-motion";
import { Link, useRouteMatch } from "react-router-dom";


const Nav = styled(motion.nav)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: black;
  font-size: 14px;
  padding: 20px 60px;
  color: white;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
`;

const Items = styled.ul`
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  position: relative;
  margin-right: 20px;
  color: ${(props) => props.theme.white.darker};
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.white.lighter};
  }
`;

const PointCircle = styled(motion.div)`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.red};
  left: 0;
  right: 0;
  margin: 5px auto;
`;

const navVariants = {
  top: {
    backgroundColor: "rgb(21 19 19 / 95%)",
  },
};

function Header() {
  const homeMatch = useRouteMatch("/");
  const infoMatch = useRouteMatch("/information");
  const contactMatch = useRouteMatch("/contact");

  const homeRouteEnabled: boolean = true;
  const infoRouteEnabled: boolean = true;
  const contactRouteEnabled: boolean = true;

  return (

    <Nav
      variants={navVariants}
      initial="top"
    >
      <Col>
        <Items>
          {homeRouteEnabled && <Item>
            <Link to="/">
              Home {homeMatch?.isExact && <PointCircle layoutId="circle" />}
            </Link>
          </Item>}
          {infoRouteEnabled && <Item>
            <Link to="/information">
              Information {infoMatch && <PointCircle layoutId="circle" />}
            </Link>
          </Item>}
          {contactRouteEnabled && <Item>
            <Link to="/contact">
              Contact {contactMatch && <PointCircle layoutId="circle" />}
            </Link>
          </Item>}
        </Items>
      </Col>
      <Col>
      <Items>
          <Item>
            <Link to={{ pathname: "https://coalitionfreeamerica.org/donate/" }} target="_blank" rel="noopener noreferrer">
              Donate
            </Link>
          </Item>
        </Items>
      </Col>
    </Nav>
  );
}

export default Header;
