import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import Contact from "./Routes/Contact";
import Information from "./Routes/Information";
import Main from "./Routes/Main";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/information">
            <Information />
          </Route>
          <Route path={["/contact", "contact/:lastName/:firstName/:zipCode/:yearOfBirth"]} exact component={Contact} />
          <Route path={["/", "/voter/:ln/:fn/:zip/:yob"]} exact component={Main}/>
        </Switch>
      </Router>
    </>
  );
}

export default App;
