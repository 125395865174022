import { useQuery } from "react-query";
import { Box, Flex } from "rebass";
import { getContactByEmail,  IMessage } from "../api";


export type Props = {
    email: string
  };

export const ContactResults: React.FC<Props> = ({
        email
    }) => {
    
    // Check the Contacts API for records
    const { data: contact, isLoading, isError, error } = useQuery<IMessage>(["contact", email], () => getContactByEmail(email));

    if (isLoading) {
        return (<div>Loading...</div>);
    } else {
        console.log("Loaded", contact);
    }

    if (isError) {
        console.log("Error connecting.", error);
        return (<div>Service are currently down. Contact Support.</div>);
    }
    
    return (
        <>
        <Flex>
          <Box>Someone will contact you soon for your information.</Box>
        </Flex>
        </>
    )
}