import { useQuery } from "react-query";
import { getVotersHistory, IVoterResults } from "../api";
import { VoterRecords } from "./VoterRecords";

export type Props = {
    firstName: string;
    lastName: string;
    zipCode: string;
    yearOfBirth: string;
  };

export const VoterHistory: React.FC<Props> = ({
        firstName, 
        lastName, 
        zipCode, 
        yearOfBirth
    }) => {
    
    // Check the VoterHistory API is up and running.
    const { data: voterResults, isLoading, isError, error } = useQuery<IVoterResults>(["voterHistory", firstName,lastName,zipCode,yearOfBirth], () => getVotersHistory(lastName, firstName, zipCode, yearOfBirth));

    if (isLoading) {
        return (<div>Loading...</div>);
    } else {
        console.log("Loaded", voterResults);
    }

    if (isError) {
        console.log("Error connecting.", error);
        return (<div>Voter History Service are currently down. Contact Support.</div>);
    }
    
    console.log('Populating VoterHistory', firstName, lastName, zipCode, yearOfBirth)
    return (
        <>
        { voterResults &&
            <VoterRecords recordsWrapper={voterResults}/>
        }
        </>
    )
}