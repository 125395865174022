
import { Link } from "react-router-dom";
import { Banner, ContentContainer, Notes, OverView, Title, Wrapper } from "../Components/Common";

function Information() {

  return (
    <Wrapper>
        <>
          <Banner>
            <Title>Audit Your Vote</Title>
            <OverView>Did your vote count in the November 3, 2020 election?</OverView>
            <Notes>Michigan voters have found their state records are indicating they did not participate in the election on November 3, 2020.</Notes>
          </Banner>
          <ContentContainer>
            <OverView>What can you do if the records show you did not vote?</OverView>
            <Notes>See the Contact form and provide your information so that we may assist in getting an Affidavit.</Notes>
            <OverView>What is a disappeared voter?</OverView>
            <Notes>This is a voter that shows as not having voted where it is clear that voter participated in that election.</Notes>
            <OverView>Nothing is wrong with my vote record, how can I help?</OverView>
            <Notes>Fill out the <b><Link to="/contact">CONTACT PAGE</Link></b> and someone will contact you. Or reach out to the <Link to={{ pathname: "https://coalitionfreeamerica.org/" }} target="_blank" rel="noopener noreferrer">COALITION FOR FREE AMERICA.</Link>.</Notes>
            <OverView>How are you using my information?</OverView>
            <Notes>We do not sell any of your information and it is used for the sole purpose of getting you help with an Affidavit.</Notes> 
        
          </ContentContainer>
         </>
    </Wrapper>
  );
}

export default Information;
