import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading } from "rebass";
import { Label } from "@rebass/forms";
import { IVoterHistoryRecord } from "../api";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {  handleSummary, ISummary } from "../utils";

const DetailsWrapper = styled(Box)`
    padding-left: .5rem;
    font-size: 24px;
    margin-bottom: 2px;
`

const Details = styled(Box)`
    font-size: 16px;
    margin-bottom: 2px;
`

const Description = styled(Box)`
    padding-left: .5rem;
    font-size: 24px;
    margin-bottom: 2px;
`

export type Props = {
    record: IVoterHistoryRecord
  };

export const VoterRecord: React.FC<Props> = ({
    record
}) => {
    const [summary, setSummary] = useState<ISummary>();


    useEffect(() => {
        setSummary(handleSummary(record));
    },[record]);

    return ( 
        <Box
         paddingLeft='0rem'
         paddingRight='0rem'
         paddingBottom='1rem'
         paddingTop='2rem'
        sx={{
            px: 4,
            py: 6,
            backgroundSize: 'cover',
            borderRadius: 8,
            color: 'white',
        }}> 
            <Label htmlFor='election-date' fontSize={2}>Election</Label>
 
            <Heading
                id='election-date'
                textAlign='left'
                paddingBottom='1rem'
                paddingLeft='.5rem'
                color={summary?.color}
                fontFamily='sans-serif'
                fontSize={[ 4, 5 ]}>
                {record.election_description}
            </Heading>
            <Flex mx={-2} mb={3}>
                <Box width={1} px={2}>

                <Label htmlFor='description' fontSize={2}>Description</Label>
                <Description fontSize={[ 3, 4 ]}
                    id='description'>
                        {summary?.description}
                </Description>

                </Box>

            </Flex>
            <Flex mx={-2} mb={3}>
            { summary?.ok && 
                <Box width={1} px={2}>
                    <Label htmlFor='details' fontSize={2}>Details</Label>
                    <DetailsWrapper>
                        <Details>Voted Absentee: {record.voted_absentee}</Details>
                        <Details>Permanent Absentee Voter: {record.is_permanent_absentee_voter}</Details>
                        <Details>City: {record.city}</Details>
                        <Details>Precinct: {record.precinct}</Details>
                        <Details>Ward: {record.ward}</Details>
                        <Details>County of Registration: {record.county_of_registration}</Details>
                        <Details>County of Vote: {record.county_of_vote}</Details>
                        <Details>Jurisdiction of Vote: {record.jurisdiction_of_vote}</Details>
                        <Details>Jurisdiction of Registration: {record.jurisdiction_of_registration}</Details>
                    </DetailsWrapper>
                </Box>
                }
            </Flex>
            <Flex mx={-2} mb={3}>
                <Box width={1} px={2}>
                    <Button
                        margin='.5rem'
                        backgroundColor='red'
                        sx={{
                            ':hover': {
                            backgroundColor: 'tomato',
                            }}}>
                        <Link to={{
                            pathname: "/contact",
                            state: { record }
                        }}>Report an Error</Link> 
                    </Button>
                </Box>
            </Flex>
        </Box>
    )
};
