import styled from "styled-components";
import bgphoto from "../images/background.png";

export const Wrapper = styled.div`
  background: black;
  padding-bottom: 100px;
  padding-top: 56px;
`;
export const Loader = styled.div`
  height: 48vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Banner = styled.div`
  height: 48vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url(${bgphoto});
  background-size: cover;
`;
export const Title = styled.h2`
  font-size: 4rem;
  margin-top: 16rem;
  margin-bottom: 1rem;
`;
export const OverView = styled.p`
  font-size: 30px;
  width: 85%;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;
export const Notes = styled.p`
  font-size: 20px;
  width: 85%;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;
export const ContentContainer = styled.div`
  margin-top: 12rem;
  font-size: 16pt;
  padding: 2rem;
`
export const ErrorLine = styled.p`
  font-size: 12pt;
  color: red;
  ::before{
    display: inline;
    content: "⚠ ";
  }
`;