// TOOD Add Security const API_KEY = "9916b135a1024e9a17e0b18e54812f2dff7eb3b0d0de123daca40bae09c99624";
const BASE_PATH = "/api";

export interface IVoterResults {
  results: IVoterHistoryRecord[]
  itemCount: number
}

export interface IContact {
  voter_id?: string
  first_name: string
  last_name: string
  zip: string
  yob: string
  email: string
  phone: string
  comments: string
}

export interface IMessage {
  message: string
}

export interface IVoterHistoryRecord {
  voter_id: string
  first_name: string
  last_name: string
  zip_code: string
  year_of_birth: string
  election_date: string
  gender: string
  election_description: string
  registration_date: string
  city: string
  state: string
  is_permanent_absentee_voter: string
  precinct: string
  ward: string
  qvf_file: string
  participation: string
  voted_absentee: string
  county_of_registration: string
  county_of_vote: string
  jurisdiction_of_registration: string
  jurisdiction_of_vote: string
}

export function getVotersHistory(lastName: string, firstName: string, zipCode: string, yearOfBirth: string) {
  return fetch(`${BASE_PATH}/vh.php?ln=${lastName}&fn=${firstName}&zip=${zipCode}&yob=${yearOfBirth}`).then(
    (response) => response.json()
  );
}

export function getVotersHistoryById(voterId: string) {
  return fetch(`${BASE_PATH}/voter_history.php/?voterId=${voterId}`).then(
    (response) => response.json()
  );
}

export function getServerStatus() {
  return fetch(`${BASE_PATH}/status.php`).then(
    (response) => response.json()
  );
}

export function getContactByEmail(email: string) {
  return fetch(`${BASE_PATH}/contacts.php?email=${email}`).then(
    (response) => response.json()
  );
}

export function postContact(contact: IContact) {
  fetch(`${BASE_PATH}/contact.php`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contact),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
  })
  .catch((error) => {
    console.error('Error:', error);
  });
}