import React from "react";
import { Box } from "rebass";
import { IVoterHistoryRecord, IVoterResults } from "../api";
import { VoterRecord } from "./VoterRecord";

export type Props = {
  recordsWrapper: IVoterResults
};

export const VoterRecords: React.FC<Props> = ({
    recordsWrapper
  }) => {

  return (
    <>
    { recordsWrapper && recordsWrapper.itemCount && 
        <Box>
          <div>Total Records: {recordsWrapper.itemCount}</div> 
            {recordsWrapper.results.map((element: IVoterHistoryRecord, index:number) => (
                <VoterRecord record={element} key={index}/>
                )
            )}

        </Box>
    }
    { (! recordsWrapper || !recordsWrapper.results) && 
        <Box>No records found. Is your Zip Code and Legal Name correct?</Box>
    }
    </>
  )

}
  

