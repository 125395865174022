import { Input, Label, Textarea } from '@rebass/forms';
import { Location } from "history";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Flex } from 'rebass';
import { IContact, postContact } from '../api';
import { ErrorLine } from './Common';
import { ContactResults } from './ContactResults';

interface IFormInput {
  fn: string;
  ln: string;
  zip: number;
  yob: number;
  email: string;
  phone: string;
  comments: string;
}

export default function ContactDetailsForm() {
  const [showSuccessfulSubmit, setShowSuccessfulSubmit] = useState<boolean>(false);

  const location = useLocation<Location>();
  
  const locationState: any = location.state;

  let seedRecord: any = {
    first_name: null,
    last_name: null,
    year_of_birth: null,
    zip_code: null
  };

  if (locationState && locationState.record) {
    const { record } = locationState;
    seedRecord = record;
  } 

  const [formData, setFormData] = useState<IFormInput>();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<IFormInput>({ mode: 'onChange' });
  
  const onSubmit = (data: IFormInput) => {
    console.log('DATA:', data);
    setFormData(data);
    console.log(JSON.stringify(formData));
    if (isValid && formData) {
      const contact: IContact = {
        voter_id: seedRecord.voter_id,
        first_name: data.fn,
        last_name: data.ln,
        zip: data.zip.toString(),
        yob: data.yob.toString(),
        email: data.email,
        phone: data.phone,
        comments: data.comments
      }
      postContact(contact);
      setShowSuccessfulSubmit(true);
      console.log('Submitted Form Data:', formData);
    }
  };

  const minYear = new Date().getFullYear() - 111;
  const maxYear = new Date().getFullYear() - 17;

  return (
    <Box>
      <Box py={1} as='form' onSubmit={handleSubmit(onSubmit)}>
        <Flex mx={-2} mb={3} >
          <Box width={1} px={2}>
            <Label pt={2} fontSize={2}>First Name</Label>
            <Input type='text' defaultValue={seedRecord.first_name}
              {...register('fn', {
                required: true,
                maxLength: 20,
                pattern: /^[A-Za-z]+$/i
              })}
              placeholder='John'
            />
            {errors?.fn?.type === 'required' && (
              <ErrorLine>This field is required</ErrorLine>
            )}
            {errors?.fn?.type === 'maxLength' && (
              <ErrorLine>First name cannot exceed 20 characters</ErrorLine>
            )}
            {errors?.fn?.type === 'pattern' && (
              <ErrorLine>Alphabetical characters only</ErrorLine>
            )}
          </Box>
          <Box width={1} px={2}>
            <Label pt={2} fontSize={2}>Last Name</Label>
            <Input type='text' defaultValue={seedRecord.last_name}
              {...register('ln', {
                required: true,
                maxLength: 50,
                pattern: /^[A-Za-z]+$/i
              })} 
              placeholder='Hancock' 
            />
            {errors?.fn?.type === 'required' && (
              <ErrorLine>This field is required</ErrorLine>
            )}
            {errors?.ln?.type === 'pattern' && (
              <ErrorLine>Alphabetical characters only.</ErrorLine>
            )}
          </Box>
          <Box width={1} px={2}>
            <Label pt={2} fontSize={2}>Zip Code</Label>
            <Input type='number'  defaultValue={seedRecord.zip_code}
              {...register('zip', {
                valueAsNumber: true,
                required: true,
                min: 10000,
                max: 99999
              })} 
              placeholder='77777' 
            />
            {errors?.fn?.type === 'required' && (
              <ErrorLine>This field is required.</ErrorLine>
            )}
            {errors.zip && (
              <ErrorLine>Must be a valid zip code.</ErrorLine>
            )}
          </Box>
          <Box width={1} px={2}>
            <Label pt={2} fontSize={2}>Year of Birth</Label>
            <Input type='number'  defaultValue={seedRecord.year_of_birth}
              {...register('yob', {
                valueAsNumber: true,
                required: true,
                max: maxYear,
                min: minYear
              })} 
              placeholder='1737' 
            />
            {errors?.yob?.type === 'required' && (
              <ErrorLine>This field is required.</ErrorLine>
            )}
            {errors?.yob?.type === 'min' && (
              <ErrorLine>Your age is over the maximum allowed.</ErrorLine>
            )}
            {errors?.yob?.type === 'max' && (
              <ErrorLine>You are too young to vote.</ErrorLine>
            )}
          </Box>
        </Flex>
        <Flex mx={-2} mb={3}> 
          <Box width={1} px={2}>
            <Label pt={2} fontSize={2}>Email</Label>
            <Input type='email'
              {...register('email', {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Enter a valid email address."
                }
              })}
              placeholder='john.hancock@america.org'  
            />
            {errors?.email?.type === 'required' && (
              <ErrorLine>This field is required.</ErrorLine>
            )}
            {errors?.email?.type === 'pattern' && (
              <ErrorLine>{errors.email.message}</ErrorLine>
            )}
          </Box>
          <Box width={1} px={2}>
            <Label pt={2} fontSize={2}>Phone</Label>
            <Input type='tel'
              autoComplete='tel'
              inputMode='numeric'
              {...register('phone', {
                required: true,
                pattern: {
                  value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                  message: "Enter a valid phone number."
                }
              })}
              placeholder='555-555-5555' 
            />
            {errors?.phone?.type === 'required' && (
              <ErrorLine>This field is required.</ErrorLine>
            )}
            {errors?.phone?.type === 'pattern' && (
              <ErrorLine>{errors.phone.message}</ErrorLine>
            )}
          </Box>
        </Flex>
        <Flex mx={-2} mb={3}> 
         <Box width={1} px={2}>
            <Label htmlFor='comments' fontSize={2}>Comments</Label>
            <Textarea
              {...register('comments', {
                required: true,
                minLength: 20
              })} 
              placeholder='What was wrong with your vote or how would you like to help...'
            />
            {errors?.comments?.type === 'required' && (
              <ErrorLine>This field is required.</ErrorLine>
            )}
            {errors?.comments?.type === 'minLength' && (
              <ErrorLine>Provide more information.</ErrorLine>
            )}
          </Box>
        </Flex>
        {isValid &&
          <Box>
            <Flex>
              <Box px={2} py={2} ml='auto'>
                <Button type='submit'
                  backgroundColor='red'
                  sx={{
                    ':hover': {
                      backgroundColor: 'tomato',
                    }
                  }}>
                  Submit
                </Button>
              </Box>
            </Flex>
          </Box>
        }
      </Box>
      { showSuccessfulSubmit && formData &&
      <Box>
        <Box>
            <ContactResults
                email={formData.email}
            /> 
        </Box>
        <Box>
          <Flex>
              <Box px={2} py={2} ml='auto'>
                <Button type='submit'
                  backgroundColor='red'
                  sx={{
                    ':hover': {
                      backgroundColor: 'tomato',
                    }
                  }}>
                  <Link to="/" >Search Again</Link>
                </Button>
              </Box>
            </Flex>
          
        </Box> 
      </Box>
      }
    </Box>
  )
}