
import { RouteComponentProps } from "react-router-dom";
import { Wrapper, Title, Banner, OverView, Notes, ContentContainer } from "../Components/Common";
import DetailsForm from "../Components/DetailsForm";

type TParams = { 
  uFn: string 
  uLn: string
  uZip: string
  uYob: string
}; 

function Main(props: RouteComponentProps<TParams>) {

  return (
    <Wrapper>
        <>
          <Banner>
            <Title>Audit Your Vote</Title>
            <OverView>Did your vote count in the November 3, 2020 election?</OverView>
            <Notes>Michigan voters have found their state records are indicating they did not participate in the election on November 3, 2020.</Notes>
            {/* <Notes>Welcome {props.match.params.uFn} {props.match.params.uLn} from zip {props.match.params.uZip} </Notes> */}
          </Banner>
          <ContentContainer>
            {/* <VoterInfo {...props}/> */}
            <DetailsForm />
          </ContentContainer>
        </>
    </Wrapper>
  );
}

export default Main;
